export default [
  {
    title: 'Accueil',
    route: 'home',
    icon: 'HomeIcon',
    action: 'manage',
    resource: 'All',
  },
  {
    title: 'Prospects',
    route: 'prospects',
    icon: 'UserPlusIcon',
    action: 'manage',
    resource: 'All',
  },
  {
    title: 'Competitions',
    route: 'competitions',
    icon: 'DribbbleIcon',
    action: 'manage',
    resource: 'All',
  },
  {
    title: 'Plateformes',
    route: 'platforms',
    icon: 'LayoutIcon',
    action: 'manage',
    resource: 'All',
  },
  {
    title: 'Utilisateurs',
    route: 'users',
    icon: 'UsersIcon',
    action: 'manage',
    resource: 'All',
  },
  {
    title: 'Messages',
    route: 'messages',
    icon: 'MessageSquareIcon',
    action: 'manage',
    resource: 'All',
  },
  {
    title: 'Alertes',
    route: 'alerts',
    icon: 'AlertCircleIcon',
    action: 'manage',
    resource: 'All',
  },
]
